<template>
  <div>
    <el-dialog
      title="提交审核"
      :visible.sync="dialogVisible"
      width="900px"
      :close-on-click-modal="false"
    >
      <span>请填写请款事由：</span>
      <el-form ref="ruleForm" :model="ruleForm" class="mt-3">
        <el-table
          :data="ruleForm.tableData"
          :header-cell-style="{ background: '#fafafa' }"
          tooltip-effect="dark"
          max-height="400px"
        >
          <el-table-column :label="$t('page.No')" type="index" width="80" align="center" />
          <el-table-column prop="cargoOwnerName" label="结算主体" />
          <el-table-column prop="currency" label="币种" />
          <el-table-column prop="requestFundsAmount" label="请款金额" />
          <el-table-column label="请款事由" align="center" min-width="160">
            <template slot-scope="scope">
              <el-form-item
                :prop="`tableData.${scope.$index}.requestFundsReason`"
                :rules=" { required: true, message: '必填', trigger: ['blur'] }"
              >
                <el-input
                  ref="textarea"
                  v-model="scope.row.requestFundsReason"
                  type="textarea"
                  maxlength="250"
                  :autosize="{ minRows: 2}"
                  show-word-limit
                />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="checkLoading" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { submitAudit, submitAuditCheck } from '@/api/vendorPurchase-api.js'

export default {

  props: {
    value: {
      type: Boolean,
      default: false
    },
    multipleSelectionTable: {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {
      ruleForm: { tableData: [] },
      checkLoading: false,
      delayReasonRules: {
        requestFundsReason: { required: true, message: this.$t('page.required'), trigger: ['change'] }
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    submitParams() {
      return Object.assign({}, { requestFundsVOList: this.ruleForm.tableData, reqFundsCodeList: this.reqFundsCodeList })
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (val) {
        this.getList()
      } else {
        // 恢复textarea高度
        console.log(this.$refs.textarea)
      }
      this.$refs.ruleForm && this.$refs.ruleForm.clearValidate()
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    async getList() {
      this.reqFundsCodeList = []
      this.multipleSelectionTable.map(item => this.reqFundsCodeList.push(item.requestFundsCode))
      const { datas = [] } = await submitAuditCheck({ reqFundsCodeList: this.reqFundsCodeList })
      this.ruleForm.tableData = datas
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          try {
            this.checkLoading = true
            const { code, msg } = await submitAudit(this.submitParams)
            if (code === 0) {
              this.$notify({
                title: msg,
                message: msg,
                type: 'success'
              })
              this.$emit('input', false)
            }
          } finally {
            this.checkLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input  .el-input__inner {
    width: 100%!important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 8px; // 纵向滚动条 必写
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #dde;
    border-radius: 3px;
}
</style>
