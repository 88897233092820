<template>
  <div>
    <el-dialog
      :title="type === 'pay' ? '付款登记' : type === 'payee'? '收款登记' : '发票登记'"
      :visible.sync="dialogVisible"
      width="600px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="form" label-position="right" label-width="150px" :model="form" :rules="rules">
        <template v-if="type === 'pay'">
          <el-form-item key="paymentAccount" label="付款账户" :class="$i18n.locale" prop="paymentAccount">
            <el-select v-model="form.paymentAccount" clearable filterable @change="handleChange">
              <el-option
                v-for="item in paymentList"
                :key="item.id"
                :label="item.cardNo"
                :value="item.cardNo"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="付款方式" :class="$i18n.locale" prop="paymentType">
            <el-select
              v-model="form.paymentType"
              :disabled="true"
              :placeholder="$t('page.selectPlaceholder')"
              filterable
            >
              <el-option v-for="ITEM in _getAllCommodityDict('PAYMENT_TYPE')" :key="ITEM.val" :label="ITEM.label" :value="ITEM.val" />
            </el-select>
          </el-form-item>
          <el-form-item label="付款金额" prop="paymentAmount">
            <el-input-number
              ref="input"
              v-model="form.paymentAmount"
              :controls="false"
              :precision="2"
              :max="row.waitPaymentAmount < 0 ? -0.01 : row.waitPaymentAmount"
              :min="row.waitPaymentAmount > 0 ? 0.01 : row.waitPaymentAmount"
              :placeholder="`${row.frozenAmount}` > 0 && `${row.waitPaymentAmount}` > 0? `冻结金额 ${row.frozenAmount}`: ''"
            />
            <!-- <el-input v-model="form.paymentAmount" type="number" :placeholder="`${row.frozenAmount}`>0?`冻结金额 ${row.frozenAmount}`:''" @blur="form.paymentAmount=blur($event)" /> -->
          </el-form-item>
          <el-form-item label="付款时间" prop="paymentTime">
            <el-date-picker
              v-model="form.paymentTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
              default-time="12:00:00"
            />
          </el-form-item>
          <el-form-item label="付款凭证：">
            <input ref="fileId" type="file" style="width: 200px" accept="image/*" @change="getFile">
            <i v-if="file" class="el-icon-circle-close" @click="clearFile" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="form.remarks"
            />
          </el-form-item>
        </template>
        <template v-else-if="type === 'payee'">
          <el-form-item key="collectAccount" label="收款账户" :class="$i18n.locale" prop="collectAccount">
            <el-select v-model="form.collectAccount" clearable filterable>
              <el-option
                v-for="item in paymentList"
                :key="item.id"
                :label="item.cardNo"
                :value="item.cardNo"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="收款方式" :class="$i18n.locale" prop="collectPaymentType">
            <el-input
              v-model="form.collectPaymentType"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item label="收款金额" prop="collectAmount">
            <el-input-number
              ref="input"
              v-model="form.collectAmount"
              :controls="false"
              :precision="2"
              :min="row.requestFundsAmount"
              :max="-0.01"
            />
          </el-form-item>
          <el-form-item label="收款时间" prop="collectTime">
            <el-date-picker
              v-model="form.collectTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="收款凭证：">
            <input ref="fileId" type="file" style="width: 200px" accept="image/*" @change="getFile">
            <i v-if="file" class="el-icon-circle-close" @click="clearFile" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="form.remarks"
            />
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="发票号" prop="invoiceCode">
            <el-input
              v-model="form.invoiceCode"
              style="display: inline"
              oninput="value=value.replace(/[^A-Za-z0-9]/g,'')"
            />
            <el-button
              v-if="form.invoiceCode"
              ref="invoiceCode"
              type="text"
              style="display: inline"
              class="ml-2"
              @click="cope(form.invoiceCode)"
            >复制</el-button>
          </el-form-item>
          <el-form-item label="发票未税金额：" prop="invoiceNoTaxAmount">
            <el-input
              v-model="form.invoiceNoTaxAmount"
              type="number"
              @blur="form.invoiceNoTaxAmount = blurTaxrate($event)"
            />
          </el-form-item>
          <el-form-item label="发票税额：" prop="invoiceTaxAmount">
            <el-input
              v-model="form.invoiceTaxAmount"
              type="number"
              @blur="form.invoiceTaxAmount = blurTaxrate($event)"
            />
          </el-form-item>
          <el-form-item label="税率：" prop="taxRate">
            <el-input
              v-model="form.taxRate"
              type="number"
              oninput="value=value.replace(/[^0-9]/g,'')"
              @blur="form.taxRate = blurTaxrateNotZero($event)"
            />
          </el-form-item>
          <el-form-item label="开票日期：">
            <el-date-picker
              v-model="form.invoiceDate"
              type="datetime"
              default-time="12:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="发票图片：">
            <input ref="fileId" type="file" style="width: 200px" @change="getFile">
            <i v-if="file" class="el-icon-circle-close ml-1" @click="clearFile" />
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="type === 'payee'" type="primary" @click="canclePayee">取消</el-button>
        <el-button v-else type="primary" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" :loading="regLoading" @click="regSubmit">登记</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { getVendorAccountListByCargoOwnerCode, getVendorAccountListByCargoOwnerCodeAndCurrency } from '@/api/blurbInformation'
import { paymentRegister, invoiceRegister, purchaseCollectRegister } from '@/api/vendorPurchase-api.js'
import { deepClone } from '@/utils'
import { validAmount, validAmountDialog } from '@/utils/validate'

export default {
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    /*     const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else if (!(/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/).test(value)) {
        return callback(new Error('请输入大于0的正数'))
      } else if (value.indexOf('.') !== -1 && value.split('.').length > 2) {
        return callback(new Error('请输入正确格式'))
      } else {
        callback()
      }
    } */
    const validateInvoiceCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else if (!/^[0-9a-zA-Z]{1,32}$/.test(value)) {
        return callback(new Error('数字和字母，32位以内'))
      } else {
        callback()
      }
    }
    /*     const validateInvoiceRate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else if (!(/^[1-9][0-9]*$/).test(value)) {
        return callback(new Error('请输入大于0的整数'))
      } else {
        callback()
      }
    } */
    return {
      form: {
        paymentAmount: undefined,
        paymentTime: '',
        invoiceNoTaxAmount: '',
        invoiceCode: '',
        invoiceTaxAmount: '',
        taxRate: '',
        invoiceDate: '',
        paymentType: '',
        remarks: '',
        collectAccount: '',
        collectPaymentType: '电汇',
        collectAmount: undefined,
        collectTime: ''
      },
      file: '',
      paymentList: [],
      changeDisabled: false,
      regLoading: false,
      rules: {
        paymentAccount: [{ required: true, message: '必填', trigger: ['change'] }],
        paymentType: [{ required: true, message: '必填', trigger: ['change'] }],
        paymentAmount: [{ required: true, message: '必填', trigger: ['blur'] }],
        paymentTime: { required: true, message: '必填', trigger: 'blur' },
        invoiceCode: [
          { required: true, message: '必填', trigger: 'blur' },
          { validator: validateInvoiceCode, trigger: 'blur' }
        ],
        invoiceNoTaxAmount: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        invoiceTaxAmount: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        taxRate: [{ required: true, message: '必填', trigger: 'blur' }],
        collectAccount: [{ required: true, message: '必填', trigger: ['change'] }],
        collectAmount: [{ required: true, message: '必填', trigger: ['blur'] }],
        collectTime: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    ticketParams() {
      return {}
    },
    payParams() {
      return {}
    },
    registerParams() {
      const formDataParams = new FormData()
      const { paymentAmount, paymentTime, paymentType, paymentAccount, remarks } = this.form
      const params = Object.assign(
        {},
        { paymentType, paymentAccount,
          paymentAmount,
          paymentTime,
          requestFundsCode: this.row.requestFundsCode,
          sysType: 0,
          remarks: remarks
        }
      )
      const obj = Object.assign({}, params, { file: this.file })
      Object.keys(obj).forEach((key) => formDataParams.set([key], obj[key]))
      return formDataParams
    },
    invoiceParams() {
      const formDataParams = new FormData()
      const obj = deepClone(this.form)
      delete obj.paymentTime
      delete obj.paymentAmount
      const params = Object.assign({}, obj, {
        requestFundsCode: this.row.requestFundsCode,
        file: this.file
      })
      Object.keys(params).forEach((key) =>
        formDataParams.set([key], params[key])
      )
      return formDataParams
    },
    payeeParams() {
      const formDataParams = new FormData()
      const { collectAccount, collectAmount, collectTime, remarks } = this.form
      const params = Object.assign(
        {},
        { collectAccount,
          collectAmount,
          collectTime,
          remarks,
          requestFundsCode: this.row.requestFundsCode
        }
      )
      const obj = Object.assign({}, params, { file: this.file })
      Object.keys(obj).forEach((key) => formDataParams.set([key], obj[key]))
      return formDataParams
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.type === 'pay') {
          this._getVendorAccountListByCargoOwnerCode(true)
        } else if (this.type === 'payee') {
          this._getVendorAccountListByCargoOwnerCodeAndCurrency()
        }
      }
      if (!val) {
        this.$refs.form.resetFields()
        this.$refs.fileId.value = ''
        this.file = ''
        this.form.remarks = ''
        Object.assign(this.form, this.$options.data.call(this).form)
      }
      this.$refs.form && this.$refs.form.clearValidate()
    }

  },
  created() { },
  mounted() { },
  methods: {
    blurTaxrateNotZero(val) {
      const value = val.target.value
      const reg = /^[0-9]*$/
      if (reg.test(value)) {
        return value
      } else {
        return ''
      }
    },
    blurTaxrate(val) {
      const value = val.target.value
      // 小数
      // console.log(validAmount(value))
      const flag =
        value &&
        value.indexOf('.') !== -1 &&
        value.split('.').length === 2 &&
        value.split('.')[1].length > 2
      if (validAmount(value)) {
        return value
      }
      if (!validAmount(value) && flag) {
        return Number(value).toFixed(2)
      }

      if (!validAmount(value)) {
        return ''
      }
    },
    cope(val) {
      if (val) {
        var input = document.createElement('input')
        input.setAttribute('readonly', 'readonly')
        input.setAttribute('value', val)
        document.body.appendChild(input)
        input.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        document.body.removeChild(input)
      }
    },
    clearFile() {
      this.file = ''
      this.$refs.fileId.value = ''
    },
    getFile() {
      const files = this.$refs.fileId.files[0]
      this.file = files
    },
    blur(val) {
      const value = val.target.value
      // 小数
      const flag =
        value &&
        value.indexOf('.') !== -1 &&
        value.split('.').length === 2 &&
        value.split('.')[1].length > 2
      if (validAmountDialog(value) && flag) {
        return Number(value).toFixed(2)
      }
      if (validAmountDialog(value)) {
        return value
      }

      if (!validAmountDialog(value)) {
        return ''
      }
    },
    async _getVendorAccountListByCargoOwnerCode(flag) {
      if (flag) {
        const { datas } = await getVendorAccountListByCargoOwnerCode({ cargoOwnerCode: this.row.cargoOwnerCode })
        this.paymentList = datas
        // this.$set(this.form, 'paymentAccount', datas.length > 0 ? datas[0].cardNo : '')
      }
    },
    async _getVendorAccountListByCargoOwnerCodeAndCurrency() {
      const { datas } = await getVendorAccountListByCargoOwnerCodeAndCurrency({
        cargoOwnerCode: this.row.cargoOwnerCode,
        currency: this.row.currency
      })
      this.paymentList = datas
    },
    handleChange(val) {
      this.form.paymentAccount = val
      const value = this.paymentList.find(e => e.cardNo === val)
      const typeVal = this._getAllCommodityDict('PAYMENT_TYPE').find(i => i.val === value.paymentType)
      if (typeVal) {
        this.form.paymentType = typeVal.val
      } else {
        this.form.paymentType = ''
      }
    },
    canclePayee() {
      this.$confirm('确定取消收款登记?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dialogVisible = false
      }).catch(() => {})
    },
    regSubmit() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            this.regLoading = true
            if (this.type === 'pay') {
              const { code, msg } = await paymentRegister(this.registerParams)
              if (code === 0) {
                this.$notify({
                  title: msg,
                  message: msg,
                  type: 'success'
                })
                this.$emit('refresh')
                this.$emit('input', false)
              }
            } else if (this.type === 'payee') {
              this.$confirm('确定收款登记?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(async() => {
                const { code, msg } = await purchaseCollectRegister(this.payeeParams)
                if (code === 0) {
                  this.$notify({
                    title: msg,
                    message: msg,
                    type: 'success'
                  })
                  this.$emit('refresh')
                  this.$emit('input', false)
                }
              }).catch(() => {})
            } else {
              const { code, msg } = await invoiceRegister(this.invoiceParams)
              if (code === 0) {
                this.$notify({
                  title: msg,
                  message: msg,
                  type: 'success'
                })
                this.$emit('refresh')
                this.$emit('input', false)
              }
            }
          } finally {
            this.regLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input .el-input__inner {
  width: 220px !important;
}
</style>
