<template>
  <div>
    <el-dialog
      :title="row.title"
      :visible.sync="dialogVisible"
      width="900px"
      center
      :close-on-click-modal="false"
    >
      <el-row class="mb-3">
        <el-col v-for="(item,index) in row.labelArr" :key="index" :span="8">{{ item.label }}：{{ item.value }}</el-col>
      </el-row>
      <Table :table-data="row.tableData1" :columns="row.columns1" :width="600" />
      <Table :table-data="row.tableData2" :columns="row.columns2">
        <el-table-column slot="ticket" label="发票号" align="center" width="150">
          <template slot-scope="scope">
            <!-- 点击查看图片 -->
            <el-button type="text" @click="viewImg(scope.row.invoiceImageUrl)">{{ scope.row.invoiceCode }}</el-button>
          </template>
        </el-table-column>
      </Table>
      <el-input v-model="row.remark" placeholder="备注" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="checkLoading" @click="invoiceCkeck(1)">审核不通过</el-button>
        <el-button type="primary" :loading="checkLoading" @click="invoiceCkeck(2)">审核通过</el-button>
      </span>
    </el-dialog>
    <ShowPhoto v-model="photoVisible" :url="url" />

  </div>
</template>

<script>
import Table from '@/components/Table'
import { invoiceCkeck } from '@/api/vendorPurchase-api.js'
import ShowPhoto from '@/components/ImageView'

export default {
  components: { Table, ShowPhoto },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    }

  },
  data() {
    return {
      url: '',
      photoVisible: false,
      checkLoading: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    viewImg(url) {
      this.url = url
      this.photoVisible = true
    },
    async invoiceCkeck(status) {
      try {
        this.checkLoading = true
        const { tableData1: taxList, tableData2: invoiceList, labelArr, remark = '' } = this.row
        const { value: requestFundsCode } = labelArr.find(item => item.label === '请款单') || []
        const params = Object.assign({}, { remark, status, requestFundsCode, taxList, invoiceList })
        const { code, msg } = await invoiceCkeck(params)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.$emit('input', false)
          this.$emit('refresh')
        }
      } finally {
        this.checkLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input  .el-input__inner {
    width: 100%!important;
}
</style>
